import type { PageType } from '@/types';
import type { ElementOptions, ElementTypes, GeneralOptions, ScreenSize, Spacing, Style } from './options';

export type SectionOptions = GeneralOptions & {
  removable: boolean;
  spacing?: Spacing;
  margin?: Spacing;
  forceDesktopBG?: boolean;
  background?: Record<ScreenSize, string>;
  backgroundRepeat?: boolean;
};

export type QuestionOption = {
  id?: string;
  name: string;
  image: string;
  type: number;
  optionId: string;
  hideImage?: boolean;
  customizeFontColor?: boolean;
  fontColor?: { regular: string; };
  customizeBgColor?: boolean;
  bgColor?: { regular: string; };
};

export type QuestionAttr = {
  options?: QuestionOption[];
};

export type Element<T extends ElementTypes> = {
  id: string;
  ref: string;
  /**
    @description conditionalRef key can be used to define elements and different elements can have same conditionalRef.
    For example it can be used to show/hide elements with same conditionalRef in certain situations.
  */
  conditionalRef?: string;
  type: ElementTypes;
  name: string;
  value: string;
  /**
    @description As object: Record<string, string | string[]>.
    The value can be `{"label": "Label"}` or `{"label": ["Label"]}` as string
  */
  values: string;
  conditionalValue?: string | undefined;
  tagName: string;
  draggable: boolean;
  attr: Record<string, any> & QuestionAttr;
  style: Style;
  on?: Record<string, any>;
  slotName?: string;
  wrapperClass?: string; // Applies class(es) for element wrapper in editor
  options: ElementOptions[T];
  elements: Element<ElementTypes>[];
};

export type Column = {
  id: string;
  ref: string;
  colspan: number;
  attr: Record<string, any>;
  style: Style;
  options: GeneralOptions;
  elements: Element<ElementTypes>[];
};

export enum SectionType {
  header = 'header',
  surveyheader = 'surveyheader',
  body = 'body',
  question = 'question',
  footer = 'footer',
  zotloFooter = 'zotloFooter',
  register = 'register',
  paywall = 'paywall',
  payment = 'payment',
  paymentSuccess = 'paymentSuccess',
  surveyending = 'surveyending',
}

export enum RegisterType {
  Email = 'email',
  PhoneNumber = 'phoneNumber',
}

export type Section = {
  id: string;
  ref: string;
  type: keyof typeof SectionType;
  name: string;
  draggable: boolean;
  attr: Record<string, any>;
  dark?: boolean;
  style: Style;
  options: SectionOptions;
  columns: Column[];
};

export type Page = {
  id: string | number;
  name: string;
  type: PageType;
  style: Style;
  meta: Record<string, any>;
  sections: Section[];
};

export type PageBGOptions = {
  backgroundColor: string;
  forceDesktopBG: boolean;
  backgroundImage: Record<ScreenSize, string>;
  backgroundRepeat?: boolean;
};

export type PageOptions = {
  fontFamily: string;
  fontFamilies: Record<string, string[]>;
  borderRadius: number;
  colors: {
    theme: string[];
    text: string[];
  };
  pages: Record<PageType, PageBGOptions>;
};
